<!--
 * @Description: 应用中心 > 项目应用 > 常用电话 > 常用电话配置
 * @Author: 露露
 * @Date: 2019-08-13 13:32:57
 * @LastEditors: 小广
 * @LastEditTime: 2019-08-20 11:40:44
 -->
<template>
  <div class="CommunityTelphoneList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :isMultiSelect="true"
    >
      <template #headerSlot>
        <v-button text="新增-导入电话" permission="add" @click="create"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="电话名称" v-model="searchParams.numRemark"></v-input>
        <v-select label="电话类型" v-model="searchParams.numType" :options="numTypeOps"></v-select>
        <v-input label="电话号码" v-model="searchParams.num"></v-input>
        <v-select2 label="所属项目" v-model="searchParams.communityId" v-bind="communityParams"></v-select2>
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" permission="update" @click="edit(scope.row)"></v-button>
        <v-button text="删除" type="text" permission="delete" @click="telTrash(scope.row)"></v-button>
      </template>
      <template #batchSlot="scope">
        <v-button text="批量删除" permission="delete" @click="batch(scope.selectedData)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getListURL, exportListURL, batchURL, deletedURL } from './api'
import { numTypes, numTypeOps } from './map'
import qs from 'qs'
import { communityParams } from 'common/select2Params'

export default {
  name: 'CommunityTelphoneList',
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      statusOps: [
        {
          text: '全部',
          value: undefined
        }, {
          text: '开启',
          value: 1
        }, {
          text: '关闭',
          value: 0
        }
      ],
      numTypeOps: numTypeOps,
      communityParams,
      searchParams: {
        numRemark: '',
        numType: undefined,
        num: '',
        communityId: ''
      },
      headers: [
        {
          prop: 'communityName',
          label: '项目名称'
        },
        {
          prop: 'numRemark',
          label: '电话名称'
        },
        {
          prop: 'num',
          label: '电话号码'
        },
        {
          prop: 'numTypesText',
          label: '电话类型',
          formatter (row) {
            return numTypes[row.numType]
          }
        },
        {
          prop: 'sortNum',
          label: '排序号'
        }
      ]
    }
  },
  methods: {
    updateStatus (ids, batchURL) {
      let _this = this
      let _ids = ids.join(',')
      let postData = {
        communityTelephoneIds: _ids,
        dataObject: _ids
      }
      _this.$axios.post(batchURL, qs.stringify(postData)).then(res => {
        if (res.status === 100) {
          _this.$refs.list.searchData()
          _this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
        }
      })
    },
    async batch (selected) {
      let isOk = await this.$confirm('确认删除?')
      isOk && this.updateStatus(selected.ids, batchURL)
    },
    async telTrash (row) {
      let isOk = await this.$confirm('确认删除?')
      isOk && this.deletedData(row)
    },
    deletedData (row) {
      let _this = this
      let postData = {
        id: row.id,
        dataObject: row.numRemark
      }
      _this.$axios.post(deletedURL, postData).then(res => {
        let returnStatus = String(res.status)
        if (returnStatus === '100') {
          _this.$refs.list.searchData()
          _this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
        }
      })
    },
    create () {
      this.$router.push({
        name: 'communityTelphoneForm'
      })
    },
    edit (row) {
      this.$router.push({
        name: 'communityTelphoneUpdateForm',
        query: {
          id: row.id
        }
      })
    }
  }
}
</script>
